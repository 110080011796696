/**
 * This is the flat list component
 * Use cases : Used for the auto scroll pagination lists
 * WARNING: THIS COMPONENT SHOULD NOT BE CHANGED UNLESS ABSOLUTELY NECESSARY. IF CHANGES ARE NECESSARY, DISCUSS IT WITH THE TEAM PRIOR
 * @param {boolean} renderInsideFragment - this is used as most of our codebase is using the original section tag for wrapper,
 *                                but in the case of Middlebody 5 which uses a reusable styled component used in multiple places,
 *                                to style it's direct underlying children directly, therefore fragment is used for such case
 */
import React, { useCallback, useEffect, useRef } from 'react';

const isInViewport = (el, partiallyVisible = false, minThreshold = { bottom: 100 }) => {
  if (el) {
    const { top, left, bottom, right } = el.getBoundingClientRect();
    const { innerHeight, innerWidth } = window;
    const isVisible = partiallyVisible
      ? ((top > 0 && top < innerHeight) ||
          (bottom - minThreshold.bottom > 0 && bottom < innerHeight)) &&
        ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
      : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
    return isVisible;
  }
};

const FlatListSkeleton = ({
  isLoaderVisible,
  onScrollEnd,
  isNext,
  LoaderComponent,
  loaderComponentCount = 3,
  renderList,
  loaderProps,
  renderInsideFragment = false,
  onVisible,
  waitForScrollStopMs = 250,
}) => {
  const wholeElRef = useRef(null);
  const handleScroll = useCallback(() => {
    if (isNext && onVisible && isInViewport(wholeElRef.current, true)) {
      onVisible();
    }
    // eslint-disable-next-line no-undef
    if (isNext && onScrollEnd && isInViewport(lastElRef.current, true)) {
      onScrollEnd();
    }
  }, []);

  useEffect(() => {
    handleScroll();
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const _RenderChildren = () => (
    <>
      {/* List */}
      {renderList()}

      {/* Loader component */}
      {isLoaderVisible && LoaderComponent && (
        <>
          {new Array(loaderComponentCount).fill(0).map((el, index) => (
            <LoaderComponent {...loaderProps} key={`${index}-loaderComponent`} />
          ))}
        </>
      )}

      {/* On Scroll end div */}
      {!onVisible && isNext && !isLoaderVisible && onScrollEnd && (
        // eslint-disable-next-line no-undef
        <div className="show-more" ref={lastElRef}></div>
      )}
    </>
  );

  return renderInsideFragment ? (
    _RenderChildren()
  ) : (
    <>
      <section ref={wholeElRef}>{_RenderChildren()}</section>
    </>
  );
};

export default FlatListSkeleton;
