import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CollectionCategorySideRender } from './CollectionCategorySideRender.js';
import { fetchCollectionCatalogItemById } from '@/redux/actions';
import { Card } from '../Card/index.js';
import { useItemClick } from 'src/hooks/useItemClick';
import FlatListSkeleton from '../../../components/FlatList/FlatListSkeleton.js';
import { useSSRSelector } from '@/redux/ssrStore';
import { IS_DESKTOP } from '@/utils/checkClientDeviceType.js';

const CollectionCategoryProductWidget = () => {
  const [onItemClick] = useItemClick();
  const dispatch = useDispatch();
  const prevCollectionIdCategoryId = useRef({});
  const [colRef, setColRef] = useState({});
  const [collectionCategoryActive, setCollectionCategoryActive] = useState({
    collectionId: null,
    categoryId: null,
  });

  // This ref keeps track of category that's clicked from left section category list.
  const categoryClickedToBeScrolled = useRef(null);

  const { collections, storeData } = useSSRSelector((state) => ({
    collections: state.catalogReducer.collections,
    storeData: state.storeReducer.store,
  }));

  const { collectionCatalogItems } = useSelector((state) => ({
    collectionCatalogItems: state.catalogReducer.collectionCatalogItems,
  }));

  useEffect(() => {
    if (collections?.length && !Object.keys(colRef).length) {
      const collectionRef = collections?.reduce((acc, currentCol) => {
        const categoryRef = currentCol?.categories?.reduce((acc, currentCat) => {
          acc[currentCat.id] = React.createRef();
          return acc;
        }, {});

        acc[currentCol.id] = {
          self: React.createRef(),
          categoryRef,
        };
        return acc;
      }, {});

      setColRef(collectionRef);
    }
  }, [collections, colRef]);

  useEffect(() => {
    if (Object.keys(colRef).length && collections?.length) {
      const collectionObserver = new IntersectionObserver(
        (entries) => obCallback(entries, true),
        {
          root: null,
          threshold: [0.25, 0.75],
          rootMargin: '-20%',
        }
      );

      const categoryObserver = new IntersectionObserver(
        (entries) => obCallback(entries, false),
        {
          root: null,
          threshold: 1,
        }
      );

      if (colRef && Object.keys(colRef).length) {
        collections?.forEach((collection) => {
          if (colRef[collection.id]?.self?.current)
            collectionObserver.observe(colRef[collection.id].self.current);
          Object.values(colRef[collection.id]?.categoryRef || {}).forEach((cat) => {
            if (cat?.current) categoryObserver.observe(cat.current);
          });
        });
      }
    }
  }, [colRef, collections]);

  const obCallback = useCallback(
    (entries, inCollection) => {
      const activeEntry = entries.find((entry) => entry.isIntersecting);
      if (activeEntry) {
        if (inCollection) {
          const dataSet = activeEntry.target.firstChild.getAttribute('data-id');
          setCollectionCategoryActive((prevState) => ({
            ...prevState,
            collectionId: Number(dataSet),
          }));
        } else {
          const dataSet = activeEntry.target.getAttribute('data-id');
          setCollectionCategoryActive((prevState) => ({
            ...prevState,
            categoryId: Number(dataSet),
          }));
        }
      }
    },
    [colRef]
  );

  function addCollectionIdCategoryId(collectionId, categoryId) {
    if (!prevCollectionIdCategoryId.current?.[collectionId]?.length) {
      prevCollectionIdCategoryId.current[collectionId] = [];
      prevCollectionIdCategoryId.current?.[collectionId].push(categoryId);
      return;
    }
    if (
      prevCollectionIdCategoryId.current?.[collectionId].find(
        (catId) => catId !== categoryId
      )
    ) {
      if (!prevCollectionIdCategoryId.current?.[collectionId]?.length) {
        prevCollectionIdCategoryId.current[collectionId] = [];
      }
      prevCollectionIdCategoryId.current?.[collectionId].push(categoryId);
      return;
    }
    return;
  }

  function checkIfSamePayloadApiAlreadyCalled(collectionId, categoryId) {
    if (
      !!prevCollectionIdCategoryId.current?.[collectionId]?.length &&
      prevCollectionIdCategoryId.current?.[collectionId].find(
        (catId) => catId === categoryId
      ) !== undefined
    ) {
      return true;
    }

    // Fixes improper scroll issue when category is clicked ( refer DD-3720 ) from left panel. Here we check, if the category for which this function is called contains categoryId same as the one which is clicked, then only call the API to fetch new data. Else don't call API (as it loads data of other categories and thus the layout shifts)
    if (categoryClickedToBeScrolled.current !== null) {
      return categoryClickedToBeScrolled.current !== categoryId;
    }

    return false;
  }

  function resetRef() {
    // Kept inside a timeout so that there is some time gap between scroll and resetting of ref, else it was hitting the API of other categories.
    setTimeout(() => {
      categoryClickedToBeScrolled.current = null;
    }, 1000);
  }

  const loadMoreData = (category, collection) => {
    if (
      storeData?.store_id &&
      !checkIfSamePayloadApiAlreadyCalled(collection.id, category.id)
    ) {
      dispatch(
        fetchCollectionCatalogItemById(
          {
            storeId: storeData.store_id,
            collectionId: collection.id,
            categoryId: category.id,
          },
          resetRef
        )
      );
      addCollectionIdCategoryId(collection.id, category.id);
    }
  };

  const returnNestedCollectionData = (collectionId, categoryId) => {
    const collectionIdValue = collectionCatalogItems[collectionId];
    let categoryIdValue = undefined;
    if (collectionIdValue) {
      categoryIdValue = collectionIdValue[categoryId];
    }
    return categoryIdValue;
  };

  const setAccordionId = (id) => {
    setCollectionCategoryActive((prevState) => ({
      ...prevState,
      collectionId: id,
    }));
  };

  /**
   * function to provide number of skelton to show while products are loading.
   * this is just a random number
   * there is no boundation, you can change this number when required;
   * 9 -> for 3 rows on mobile
   * 8 -> for 4 rows on desktop
   * @returns
   */
  const getSkeltonCount = () => {
    return IS_DESKTOP ? 9 : 8;
  };

  const collectionCategoryItemsList = () => {
    return (
      <div className="collection-category-items">
        {collections?.length
          ? collections.map((collection) => (
              <div
                key={collection.id}
                ref={colRef ? colRef[collection.id]?.self : null}
                className="mt56px"
              >
                <h2
                  data-id={collection.id}
                  className="collection-heading"
                  id={`collection-${collection.id}`}
                >
                  {collection.name}
                </h2>
                {collection?.categories?.map((category) => (
                  <div
                    key={category.id}
                    ref={colRef ? colRef[collection.id]?.categoryRef[category.id] : null}
                    data-id={category.id}
                    className="category-items-section"
                    id={`${collection.id}-${category.id}`}
                  >
                    <h3 className="category-item-header">{category.name}</h3>
                    <div className="category-item-body">
                      <FlatListSkeleton
                        renderOnVisible
                        isLoaderVisible={false}
                        isNext={!returnNestedCollectionData(collection.id, category.id)}
                        onVisible={() => loadMoreData(category, collection)}
                        // add LoaderComponent for loading sections
                        renderList={() => {
                          const collectionData = returnNestedCollectionData(
                            collection.id,
                            category.id
                          );
                          return collectionData
                            ? collectionData.map((data) => (
                                <Card
                                  key={data.id}
                                  data={data}
                                  onItemClick={() => onItemClick(data)}
                                  showButton={true}
                                  noTags={false}
                                />
                              ))
                            : new Array(getSkeltonCount()).fill(0).map((_0, index) => {
                                return (
                                  <Card
                                    key={index}
                                    skeleton
                                    skeletonImage="https://i.imgur.com/ewkhcIA.gif"
                                  />
                                );
                              });
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            ))
          : null}
      </div>
    );
  };

  return (
    <section
      className="CollectionCategoryProductWidget1"
      id="CollectionCategoryProductWidget"
    >
      <div className="CollectionCategoryProductWidget1__body">
        {IS_DESKTOP && (
          <div className="left-section">
            <CollectionCategorySideRender
              collectionCategoryString={collectionCategoryActive}
              setAccordionId={(id) => setAccordionId(id)}
              categoryClickedToBeScrolled={categoryClickedToBeScrolled}
            />
          </div>
        )}
        <section className="right-section">{collectionCategoryItemsList()}</section>
      </div>
    </section>
  );
};

export default CollectionCategoryProductWidget;
